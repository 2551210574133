.App {
  text-align: center;
  background-color: #f5f0f0; /*background color*/
  min-height: 100vh;
  font-size: calc(9px + 2vmin);
  color: #2e2525; /*text color*/
  display: flex;
  flex-direction: column;
}

p {
  margin-left: 5%;
  width: 90%;
}

/* if screen is smaller than iphone 12 pro, make font-size smaller */
@media (max-width: 390px) {
  .App {
    font-size: calc(8px + 2vmin);
  }
}

.App-container {
  padding-top: 3%;
}

.explanation {
  margin-bottom: 3em;
}

.task {
  margin-bottom: 2em;
}

.blurred {
  filter: blur(8px);
  pointer-events: none;
  user-select: none;
}

body {
  margin: 0;
  /* modern lightweight font */
  font-family: hero-new, sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 700px) {
  .imgdiv {
    position: absolute;
    left: 0;
    margin-top: 100px;
    
  }
  .imgtop {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .imgdiv {
    position: absolute;
    right: 0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-top: 45px;
  }
  .imgbottom {
    display: none;
  }
}

input[type="text"] {
  color: #34282c;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: calc(1rem + 2vmin);
  border-radius: 3px;
  width: 80%;
  max-width: 650px;
  border: 1px solid #c1b8b8; /*bottom boxes edge color*/
  background-color: #f0e5e5; /*bottom boxes fill color*/
  color: #2e2525; /*bottom boxes edge color*/
}

input[type="text"]:focus {
  border: 2px solid #8080ff;
}

input[type="text"].error {
  border: 2px solid #e06c75;
}

.tex-border {
  border: 2px solid #a0a0a0;
  width: 100%;
  padding: 10px;
  border-radius: 3px;
}

.tex-container {
  height: calc(80px + 2vmin);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.external-links {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 40px;
  align-items: center;
  color: rgb(203, 198, 192);
  text-decoration: none;
  font-size: small;
}

.error-hint {
  margin-left: 15px;
  margin-right: 15px;
}

a:hover {
  color: #2e2525;
}

a:hover > svg {
  fill: #2e2525;
}

/* style any button  */
button {
  background-color: #2e2525;
  color: white;
  padding: 10px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 40%;
  border-radius: 30px;
  font-size: calc(1rem + 2vmin);
  transition: background-color 0.2s ease-in-out;
}

button:hover {
  background-color: #2e2525; /* A darker, sophisticated hover */
  color: #fefae0;
}

.notyou {
  /* this is a small href link that says: 'not you?' - so small font - same line as the previous h5*/
  font-size: 0.8rem;
  color: #2e2525;
  text-decoration: none;
  display: inline;
}

h5.top {
  display: inline;
  font-size: xx-large;
}


/* define the width of any child of the class 'formulaCombined' */
.formulaCombined > * {
  width: 90%;
}

/* for mobile, font-size is xx-large */
@media (max-width: 600px) {
  h5.top {
    font-size: x-large;
  }
}

p.final {
  margin-top: 25px;
  font-weight: bold;
}

h3 {
  /* h3 is full width, all caps, slight colored background */
  width: 80%;
  background-color: #ffd9d9; /*Gender bunner background color*/
  color: #352e2d; /*Gender text color*/
  text-transform: uppercase;
  padding: 10px 0;
  border-radius: 5px;
  /* center text */
  text-align: center;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 110px;
}

.confettiexplosion1 {
  position: absolute;
  bottom: 20%;
  left: 25%;
  /* width: 100%; */
  z-index: 100;
  pointer-events: none;
}
.confettiexplosion2 {
  position: absolute;
  bottom: 20%;
  left: 75%;
  /* width: 100%; */
  z-index: 100;
  pointer-events: none;
}
